import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/Admin.css";
import "./assets/css/DashboardResponsive.css";
import "./assets/css/Customer.css";
import "./assets/css/responsive.css";
import "./assets/css/CustomerDashboardResponsive.css";
import "./assets/css/Practitioner.css";

const Homepage = lazy(() => import("./Pages/Homepage"));
const PaymentStepForm = lazy(() => import("./Pages/PaymentStepForm"));
const NotFound = lazy(() => import("./Pages/Error/NotFound"));
const RecomendedTest = lazy(() => import("./Pages/Quiz/RecomendedTest"));
const Product = lazy(() => import("./Pages/Product"));
const Login = lazy(() => import("./Pages/Login"));
const AdminLogin = lazy(() => import("./Pages/AdminLogin"));
const Checkout = lazy(() => import("./Pages/Checkout"));
const Dashboard = lazy(() => import("./Admin/Dashboard"));
const Verify = lazy(() => import("./Pages/Verify"));
const VerificationCode = lazy(() => import("./Pages/VerificationCode"));
const Quiz = lazy(() => import("./Pages/Quiz/Quiz"));
const QuizIndex = lazy(() => import("./Pages/Quiz/QuizIndex"));
const QuizStart = lazy(() => import("./Pages/Quiz/QuizStart"));
const PractitionerAdmin = lazy(() => import("./Admin/PractitionerAdmin"));
const PractitionerCustomerList = lazy(() =>
  import("./Admin/PractitionerCustomerList")
);
const CustomerRequest = lazy(() => import("./Admin/CustomerRequest"));
const PractitionerDetail = lazy(() => import("./Admin/PractitionerDetail"));
const CustomerDetial = lazy(() => import("./Admin/CustomerDetial"));
const AdminRecommendationDetail = lazy(() => import("./Admin/AdminRecommendationDetail"));
const CustomerTest = lazy(() => import("./Admin/CustomerTest"));
const TestReport = lazy(() => import("./Admin/TestReport"));
const NewPassword = lazy(() => import("./Pages/NewPassword"));
const CustomerList = lazy(() => import("./Admin/CustomerList"));
const HealthProfile = lazy(() => import("./Admin/HealthProfile"));
const AdminProduct = lazy(() => import("./Admin/ProductsPages/AdminProduct"));
const PreviewBiomarkers = lazy(() => import("./Admin/PreviewBiomarker"));

const Buildtest = lazy(() => import("./Pages/BuildOwn"));
const AddProducts = lazy(() => import("./Admin/ProductsPages/AddProducts"));
const Biomarkers = lazy(() => import("./Admin/Biomarkers"));
const AddBiomarker = lazy(() => import("./Admin/AddBiomarker"));
const TestType = lazy(() => import("./Admin/TestType"));
const Contact = lazy(() => import("./Pages/Contact"));
const Baseline = lazy(() => import("./Pages/Baseline"));
const Periemenopause = lazy(() => import("./Pages/Periemenopause"));
const Thyriod = lazy(() => import("./Pages/Thyriod"));
const About = lazy(() => import("./Pages/About"));
const Affiliate = lazy(() => import("./Pages/Affiliate"));
const Corporate = lazy(() => import("./Pages/Corporate.js"));

const TeamMember = lazy(() => import("./Pages/TeamMember"));
const Collection = lazy(() => import("./Pages/Collection"));
const Register = lazy(() => import("./Pages/Register"));
const Faqs = lazy(() => import("./Pages/Faqs"));
const ProductInner = lazy(() => import("./Pages/ProductInner"));
const HealthHub = lazy(() => import("./Pages/HealthHub"));
const BlogsDetail = lazy(() => import("./Pages/BlogsDetail"));
const AccountDemo = lazy(() => import("./Pages/AccountDemo"));
const Operator = lazy(() => import("./Admin/OperatorPages/Operator"));
const AddOperator = lazy(() => import("./Admin/OperatorPages/AddOperator"));
const Buildown = lazy(() => import("./Admin/BuildOwn/Buildown"));
const BuildownAdd = lazy(() => import("./Admin/BuildOwn/BuildownAdd"));
const OrderList = lazy(() => import("./Admin/Order/OrderList"));
const OrderDetail = lazy(() => import("./Admin/Order/OrderDetail"));
const ReferrlForm = lazy(() => import("./Pages/ReferrlForm"));
const ExternalReport = lazy(() => import("./Admin/Reports/ExternalReport"));
import Loader from "./Components/Loader";
const Invoice = lazy(() => import("./Admin/Invoice"));
const Recommendation = lazy(() =>
  import("./Admin/Recommendation/Recommendation")
);
const Payment = lazy(() => import("./Pages/Payment"));
const Practitioner = lazy(() => import("./Pages/Practitioner"));
const AllBlogs = lazy(() => import("./Admin/Blogs/AllBlogs"));
const AddBlogs = lazy(() => import("./Admin/Blogs/AddBlogs"));
const Subcriptions = lazy(() => import("./Admin/Subcriptions/Subcriptions"));
const ManageSubcription = lazy(() =>
  import("./Admin/Subcriptions/ManageSubcription")
);
// practioner
const PractitionerProfileDetail = lazy(() =>
  import("./Practitioner/PractitionerProfileDetail")
);
const PractitionerDashboard = lazy(() =>
  import("./Practitioner/PractitionerDashboard")
);

const PractitionerRecommendationDetail = lazy(() => import("./Practitioner/PractitionerRecommendationDetail"))

const PractitionerSetting = lazy(() =>
  import("./Practitioner/PractitionerSetting")
);
const PractitionerEditProfile = lazy(() =>
  import("./Practitioner/PractitionerEditProfile")
);
const PractitionerTestResult = lazy(() =>
  import("./Practitioner/PractitionerTestResult")
);
const SendTest = lazy(() => import("./Practitioner/SendTest"));

const CustomerTestName = lazy(() => import("./Practitioner/CustomerTestName"));
const PracCustomer = lazy(() => import("./Practitioner/PracCustomer"));
const CustomerTabs = lazy(() => import("./Practitioner/CustomerTabs"));
const PractitionerCheckout = lazy(() =>
  import("./Practitioner/PractitionerCheckout")
);
const ProductOrder = lazy(() => import("./Practitioner/ProductOrder"));
const PractitionerOrder = lazy(() =>
  import("./Practitioner/PractitionerOrder")
);
const PractitionerResult = lazy(() =>
  import("./Practitioner/PractitionerResult")
);
const PracClient = lazy(() => import("./Practitioner/PracClient"));
const AddClient = lazy(() => import("./Practitioner/AddClient"));
const VerifyEmail = lazy(() => import("./Components/Acc_Register/VerifyEmail"));
// customer
const HealthprofileCustomer = lazy(() =>
  import("./CustomerDashboard/HealthprofileCustomer")
);
const CustomerHealhtscore = lazy(() =>
  import("./CustomerDashboard/CustomerHealhtscore")
);
const CustomerHeartscore = lazy(() =>
  import("./CustomerDashboard/CustomerHeartscore")
);
const CustomerBiomarkerReport = lazy(() =>
  import("./CustomerDashboard/CustomerBiomarkerReport")
);
const CustomerVitalsReport = lazy(() =>
  import("./CustomerDashboard/CustomerVitalsReport")
);
const CustomerCheckUps = lazy(() =>
  import("./CustomerDashboard/CustomerCheckUps")
);
const CustomerPersonaliseCheckup = lazy(() =>
  import("./CustomerDashboard/CustomerPersonaliseCheckup")
);

const CustomerSetting = lazy(() =>
  import("./CustomerDashboard/CustomerSetting")
);
const CustomerOrderDetail = lazy(() =>
  import("./CustomerDashboard/CustomerOrderDetail")
);
const CustomerNotification = lazy(() =>
  import("./CustomerDashboard/CustomerNotification")
);
const CustomerSubscription = lazy(() =>
  import("./CustomerDashboard/CustomerSubscription")
);
const CustomerSubscriptionTwo = lazy(() =>
  import("./CustomerDashboard/CustomerSubscriptionTwo")
);
const CustomerDashboard = lazy(() =>
  import("./CustomerDashboard/CustomerDashboard")
);
const CustomerAllTest = lazy(() =>
  import("./CustomerDashboard/CustomerAllTest")
);
const CustomerBioMarker = lazy(() =>
  import("./CustomerDashboard/CustomerBioMarker")
);
const CustomerOverview = lazy(() =>
  import("./CustomerDashboard/CustomerOverview")
);
const CustomerPractitioners = lazy(() =>
  import("./CustomerDashboard/CustomerPractitioners")
);
const CustomerTestReport = lazy(() =>
  import("./CustomerDashboard/CustomerTestReport")
);
const CustomerRecommendation = lazy(() =>
  import("./CustomerDashboard/CustomerRecommendation")
);
const CustomerRecommendationBiomarker = lazy(() =>
  import("./CustomerDashboard/CustomerRecommendationBiomarker")
);
const CustomerRecommendationSteps = lazy(() =>
  import("./CustomerDashboard/CustomerRecommendationSteps")
);
const CustomerRecommendationDetail = lazy(() =>
  import("./CustomerDashboard/CustomerRecommendationDetail")
);
const CustomerAddTest = lazy(() => import("./Admin/CustomerAddTest"));

//////////////////TermsAndCondition/////////////////////////
const TermsAndCondition = lazy(() => import("./Pages/TermsAndCondition.js"));
//////////////////TermsAndCondition/////////////////////////

//////////////////PrivacyAndPolicy/////////////////////////
const PrivacyAndPolicy = lazy(() => import("./Pages/PrivacyAndPolicy.js"));
//////////////////PrivacyAndPolicy/////////////////////////

import { getRole } from "./helpers/Helpers";
import socketIO from "socket.io-client";
import axios from "axios";
import { useNotificationContext } from "./context/Notification";

import {
  setCurrentUser,
  customerPercentagesAction,
  setSubscriptionsDetails,
} from "./Store/reducers/CurrentUser";
import { fetchPractitionerPending } from "./Store/reducers/PractitionerPending";
import { useDispatch, useSelector } from "react-redux";
import SecondaryProtectedRoutes from "./ProtectedRoutes/SecondaryAdminProtectedRoutes";
import CustomerProtectedRoutes from "./ProtectedRoutes/CustomerProtectedRoutes";
import AdminProtectedRoutes from "./ProtectedRoutes/AdminProtectedRoutes";
import { getCookie, removeCookie } from "./helpers/Helpers";
import Categories from "./Admin/Categories/Categories";

import BiomarkerDoc from "./APIs/Biomarkers/BiomarkerDoc";

import SingleBiomarkerDoc from "./APIs/Biomarkers/SingleBiomarkerDoc";
import QuizNew from "./Pages/Quiz/QuizNew";
import PractitionerProtectedRoutes from "./ProtectedRoutes/PractitionerProtectedRoutes";

function App() {
  const dispatch = useDispatch();
  const { profileUpdated, currentUser } = useSelector(
    (state) => state.currentUser
  );
  const role = getRole();
  const [token, setToken] = useState("");
  const [isAdmin, setAdmin] = useState("");
  const [isSecAdmin, setSecAdmin] = useState("");
  const [isCustomer, setCustomer] = useState("");
  const [isPractitioner, setIsPractitioner] = useState("");
  const [state, setState] = useState(true);

  let inactivityTimer;

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      removeCookie();
      window.location.reload();
    }, 30000000);
  };

  const {
    updateNotificationCount,
    setNotifications,
  } = useNotificationContext();

  const handleToken = () => {
    const value = getCookie();

    if (value) {
      const payload = value.split(".");
      const payloadValue = JSON.parse(atob(payload[1]));

      if (payloadValue.role === "1") {
        setAdmin(true);
        setSecAdmin(false);
      } else if (
        payloadValue.role === "4" ||
        payloadValue.role === "5" ||
        payloadValue.role == process.env.REACT_APP_PRACTIOTIONER_ID
      ) {
        setAdmin(true);
        setSecAdmin(true);
      } else {
        setAdmin(false);
        setSecAdmin(false);
      }

      setToken(value);
    }
  };

  useEffect(() => {
    const value = getCookie();
    if (value) {
      const payload = value.split(".");
      const payloadValue = JSON.parse(atob(payload[1]));
      let UID = payloadValue?.id;
      let uEmail = payloadValue?.email;
      let config = {
        headers: {
          Authorization: value,
        },
      };
      const fetchSubscriptionDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/subscription/details`,
            config
          );
          dispatch(setSubscriptionsDetails(response?.data?.subscription));
        } catch (err) {
          console.log("🚀 ~ fetchSubscriptionDetails ~ err:", err)
        }
      };
      const fetchUserDetail = async (uEmail) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/user/fetch-user/${uEmail}`,
            config
          );
          if (response?.data?.data?.subscriptionId) {
            fetchSubscriptionDetails();
          }
          dispatch(setCurrentUser(response?.data?.data));
        } catch (err) { }
      };
      const fetchAllPercentages = async (UID) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/health-profile/fetch-percentage/${UID}`,
            config
          );
          dispatch(customerPercentagesAction(response?.data));
        } catch (err) { }
      };
      dispatch(fetchPractitionerPending(value)); // for pending practitioner and unread practitioner count
      if (UID && uEmail) {
        fetchUserDetail(uEmail);
        fetchAllPercentages(UID);
      }
    }
  }, [getCookie(), profileUpdated]);

  useEffect(() => {
    handleToken();
  }, []);

  const fetch = async (email) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/practitioner/notifications`,
        {
          email: email,
        }
      );

      const unreadCount = data?.data?.notifications.filter(
        (notification) => !notification.isRead
      ).length;

      updateNotificationCount(unreadCount);


      setNotifications(data?.data?.notifications);
    } catch (err) { }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (role?.role == 3) {
          await fetch(role?.email);
        }
        const socket = socketIO.connect(process.env.REACT_APP_BASE_URL);

        socket.emit("storeUserInfo", { userId: role?.id, email: role?.email });

        socket.on("notification", ({ status, message }) => {
          const newNotification = {
            id: 0,
            activity: status,
            createdAt: new Date(),
            activity: message,
          };
          setNotifications((prevNotifications) => [
            newNotification,
            ...prevNotifications,
          ]);
          updateNotificationCount((prev) => prev + 1);
        });

        // Handle socket connection errors
        socket.on("connect_error", (error) => {
          console.error("WebSocket connection error:", error);
          socket.disconnect();
        });

        // Handle socket disconnect
        socket.on("disconnect", () => { });
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();

    return () => {
      try {
        if (socketIO) {
          socketIO.off("notification");
          socketIO.off("storeUserInfo");
          socketIO.disconnect(); // Disconnect socket on component unmount
        }
      } catch (err) {
        console.error("Cleanup error:", err);
      }
    };
  }, [token]);

  // activity based logout

  useEffect(() => {
    // Attach event listeners to track user activity when the component mounts
    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("mousedown", resetInactivityTimer);
    document.addEventListener("keydown", resetInactivityTimer);
    // Add more events as needed

    // Cleanup event listeners when the component unmounts
    return () => {
      document.removeEventListener("mousemove", resetInactivityTimer);
      document.removeEventListener("mousedown", resetInactivityTimer);
      document.removeEventListener("keydown", resetInactivityTimer);
      // Remove additional events
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setState(false);
    }, 3000);
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/payment-step-form"
            element={<PaymentStepForm setToken={setToken} />}
          />
          <Route path="/products" element={<Product />} />
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route
            path="/admin-login"
            element={<AdminLogin setToken={setToken} />}
          />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/VerificationCode" element={<VerificationCode />} />
          <Route path="/Baseline" element={<Baseline />} />
          <Route path="/Periemenopause" element={<Periemenopause />} />
          <Route path="/Thyriod" element={<Thyriod />} />
          <Route path="/about" element={<About />} />
          <Route path="/Affiliate" element={<Affiliate />} />
          <Route path="/Corporate" element={<Corporate />} />
          <Route path="/Practitioner" element={<Practitioner />} />
          <Route path="/TeamMember" element={<TeamMember />} />
          <Route path="/Collection" element={<Collection />} />
          <Route path="/Buildtest" element={<Buildtest />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/ProductInner" element={<ProductInner />} />
          <Route path="/HealthHub" element={<HealthHub />} />
          <Route path="/blog-details/:blogId" element={<BlogsDetail />} />
          <Route path="/AccountDemo" element={<AccountDemo />} />
          <Route path="/ReferrlForm" element={<ReferrlForm />} />
          <Route path="/Invoice" element={<Invoice />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/NewPassword" element={<NewPassword />} />
          <Route path="/Quiz" element={<Quiz />} />
          <Route path="/RecomendedTest" element={<RecomendedTest />} />
          <Route path="/QuizStart" element={<QuizStart />} />
          <Route path="/QuizIndex" element={<QuizIndex />} />
          <Route path="/QuizNew" element={<QuizNew />} />
          {/* ////////////////////TermsAndCondition//////////////// */}
          <Route path="/Terms-And-Condition" element={<TermsAndCondition />} />
          {/* ////////////////////TermsAndCondition//////////////// */}
          {/* ////////////////////PrivacyAndPolicy//////////////// */}
          <Route path="/Privacy-And-Policy" element={<PrivacyAndPolicy />} />
          {/* ////////////////////PrivacyAndPolicy//////////////// */}
          {/* admin start */}

          <Route
            element={
              <SecondaryProtectedRoutes
                setToken={setToken}
                setAdmin={setAdmin}
                setSecAdmin={setSecAdmin}
                isSecAdmin={isSecAdmin}
                isAdmin={isAdmin}
                token={token}
              />
            }
          >
            <Route path="/Subcriptions" element={<Subcriptions />} />
            <Route path="/ManageSubcription" element={<ManageSubcription />} />
            <Route path="/Categories" element={<Categories />} />
            <Route path="/AdminPanel" element={<Dashboard />} />
            <Route path="/PractitionerAdmin" element={<PractitionerAdmin />} />
            <Route path="/AdminRecommendationDetail" element={<AdminRecommendationDetail />} />
            <Route
              path="/practitioner-customer-list"
              element={<PractitionerCustomerList />}
            />
            <Route path="/CustomerRequest" element={<CustomerRequest />} />
            <Route
              path="/PractitionerDetail"
              element={<PractitionerDetail />}
            />
            <Route path="/CustomerDetial" element={<CustomerDetial />} />
            <Route path="/CustomerTest" element={<CustomerTest />} />
            <Route path="/TestReport" element={<TestReport />} />
            <Route path="/CustomerList" element={<CustomerList />} />
            <Route path="/HealthProfile" element={<HealthProfile />} />
            <Route path="/adminProduct" element={<AdminProduct />} />
            <Route path="/addProducts" element={<AddProducts />} />
            <Route path="/editProducts" element={<AddProducts />} />
            <Route path="/Buildown" element={<Buildown />} />
            <Route path="/Biomarkers" element={<Biomarkers />} />
            <Route path="/AddBiomarker" element={<AddBiomarker />} />
            <Route path="/PreviewBiomarker" element={<PreviewBiomarkers />} />
            <Route path="/TestType" element={<TestType />} />
            <Route path="/Recommendation" element={<Recommendation />} />
            <Route
              element={
                <AdminProtectedRoutes
                  setToken={setToken}
                  setAdmin={setAdmin}
                  isAdmin={isAdmin}
                  token={token}
                />
              }
            >
              <Route path="/Operator" element={<Operator />} />
              <Route path="/EditOperator" element={<AddOperator />} />
              <Route path="/AddOperator" element={<AddOperator />} />
            </Route>

            <Route path="/BuildownAdd" element={<BuildownAdd />} />
            <Route path="/OrderList" element={<OrderList />} />
            <Route path="/OrderDetail" element={<OrderDetail />} />
            <Route path="/AllBlogs" element={<AllBlogs />} />
            <Route path="/AddBlogs" element={<AddBlogs />} />
            <Route path="/external-report" element={<ExternalReport />} />

            <Route path="/EditBiomarkers" element={<AddBiomarker />} />
            <Route path="/CustomerAddTest" element={<CustomerAddTest />} />
          </Route>

          {/* Practitioner dashboard */}
          <Route
            element={
              <PractitionerProtectedRoutes
                setToken={setToken}
                setPractitioner={setIsPractitioner}
                isPractitioner={isPractitioner}
                token={token}
              />
            }
          >
            <Route
              path="/PractitionerDashboard"
              element={<PractitionerDashboard />}
            />
            <Route
              path="/PractitionerRecommendationDetail"
              element={<PractitionerRecommendationDetail />}
            />
            <Route
              path="/PractitionerSetting"
              element={<PractitionerSetting />}
            />
            <Route
              path="/PractitionerEditProfile"
              element={<PractitionerEditProfile />}
            />
            <Route
              path="/PractitionerTestResult"
              element={<PractitionerTestResult />}
            />
            <Route path="/CustomerTestName" element={<CustomerTestName />} />
            <Route path="/PracCustomer" element={<PracCustomer />} />

            <Route path="/CustomerTabs" element={<CustomerTabs />} />
            <Route
              path="/PractitionerCheckout"
              element={<PractitionerCheckout />}
            />
            <Route path="/ProductOrder" element={<ProductOrder />} />
            <Route path="/PractitionerOrder" element={<PractitionerOrder />} />
            <Route
              path="/PractitionerResult"
              element={<PractitionerResult />}
            />
            <Route path="/PracClient" element={<PracClient />} />
            <Route path="/AddClient" element={<AddClient />} />
            <Route path="/SendTest" element={<SendTest />} />
          </Route>

          {/* customer dashboard start */}

          <Route
            element={
              <CustomerProtectedRoutes
                setToken={setToken}
                setCustomer={setCustomer}
                isCustomer={isCustomer}
                token={token}
              />
            }
          >
            <Route
              path="/PractitionerProfileDetail"
              element={<PractitionerProfileDetail />}
            />
            <Route
              path="/HealthprofileCustomer"
              element={<HealthprofileCustomer />}
            />
            <Route path="/CustomerSetting" element={<CustomerSetting />} />
            <Route
              path="/CustomerNotification"
              element={<CustomerNotification />}
            />
            <Route
              path="/CustomerOrderDetail"
              element={<CustomerOrderDetail />}
            />
            <Route path="/CustomerDashboard" element={<CustomerDashboard />} />
            <Route
              path="/Customersubscription"
              element={<CustomerSubscription />}
            />
            <Route
              path="/Customersubscriptiontwo"
              element={<CustomerSubscriptionTwo />}
            />
            <Route path="/CustomerAllTest" element={<CustomerAllTest />} />
            <Route path="/CustomerBioMarker" element={<CustomerBioMarker />} />
            <Route
              path="/biomarker-report"
              element={<CustomerBiomarkerReport />}
            />
            <Route
              path="/vital-report"
              element={<CustomerVitalsReport />}
            />
            <Route path="/CustomerOverview" element={<CustomerOverview />} />
            <Route path="/CustomerCheckUps" element={<CustomerCheckUps />} />
            <Route
              path="/CustomerRecommendation"
              element={<CustomerRecommendation />}
            />
            <Route
              path="/CustomerHealhtscore"
              element={<CustomerHealhtscore />}
            />
            <Route
              path="/CustomerHeartscore"
              element={<CustomerHeartscore />}
            />
            <Route
              path="/CustomerRecommendationBiomarker"
              element={<CustomerRecommendationBiomarker />}
            />
            <Route
              path="/CustomerRecommendationSteps"
              element={<CustomerRecommendationSteps />}
            />
            <Route
              path="/CustomerRecommendationDetail"
              element={<CustomerRecommendationDetail />}
            />
            <Route
              path="/CustomerPersonaliseCheckup"
              element={<CustomerPersonaliseCheckup />}
            />
            <Route
              path="/CustomerPractitioners"
              element={<CustomerPractitioners />}
            />
            <Route
              path="/CustomerTestReport"
              element={<CustomerTestReport />}
            />
          </Route>

          {/* api */}
          <Route path="/biomarkers-api" element={<BiomarkerDoc />} />
          <Route
            path="/single-biomarkers-api"
            element={<SingleBiomarkerDoc />}
          />
          {/* api */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
