import React from "react";
import { Link } from "react-router-dom";
function QuizAge({ data, setData, handleData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Age</h1>
        <div className="">
          <p className=" ">
            <p>
              Your age is able to tell us a lot about what your optimal health
              should look like, and whether you are at a higher risk of any
              conditions.
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource2"
              id="age1"
              value="18-49"
              checked={data?.age === "young"}
              onClick={(e) => {
                handleData("young", "age");
              }}
            />
            <label className="btn btn-outline-primary" htmlFor="age1">
              18-29
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource2"
              id="age2"
              value="30-49"
              checked={data?.age === "adult"}
              onClick={(e) => {
                handleData("adult", "age");
              }}
            />
            <label className="btn btn-outline-primary" htmlFor="age2">
              30-49
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource2"
              id="age3"
              value="50+"
              checked={data?.age === "aged"}
              onClick={(e) => handleData("aged", "age")}
            />
            <label className="btn btn-outline-primary" htmlFor="age3">
              50+
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizAge;
