import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizKidney() {
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    navigate("/RecomendedTest", {
      state: { testName: name },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Kidney</h1>
        <div className="">
          <p className=" ">
            <p>
              Kidneys don’t just filter blood they also do a lot for your blood
              pressure and energy levels. Do any of these symptoms or concerns
              apply to you?
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="kyurine"
              value="kyurine"
              onChange={() => goToRecommendation("kidney1")}
            />
            <label className="btn btn-outline-primary" htmlFor="kyurine">
              Urinary pain or change in frequency
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="kySwelling"
              value="kySwelling"
              onChange={() => goToRecommendation("kidney2")}
            />
            <label className="btn btn-outline-primary" htmlFor="kySwelling">
              Swelling or fluid retention?
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="kyHigh"
              value="kyHigh"
              onChange={() => goToRecommendation("kidney1")}
            />
            <label className="btn btn-outline-primary" htmlFor="kyHigh">
              High blood pressure or diabetes
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="kyUnexplained"
              value="kyUnexplained"
              onChange={() => goToRecommendation("kidney1")}
            />
            <label className="btn btn-outline-primary" htmlFor="kyUnexplained">
              Unexplained back pain
            </label>
          </div>
          {/* NEW CATEGORY */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="kyUnexplained"
              value="kyUnexplained"
              onChange={() => goToRecommendation("kidney1")}
            />
            <label className="btn btn-outline-primary" htmlFor="kyUnexplained">
              General Kidney Checks
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizKidney;
