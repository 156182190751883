import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCookie, getRole } from "../../helpers/Helpers";
import Deletepopup from "../../Components/Popups/Deletepopup";
import ErrorPopup from "../../Components/Popups/ErrorPopup";
import formatDate from "../../helpers/Helpers";
import { Modal } from "react-bootstrap";
import Pagination from "../../Components/Pagination";

function Categories() {
  const [popupMessage, setPopupMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [id, setID] = useState(0);
  const [dynamic, setDynamic] = useState("");
  const [category, setCategory] = useState({ id: 0, name: "" });
  const [currentPage, setCurrentPage] = useState(1);

  // items per page depends on windows height ***********************************
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 1400) {
        setItemsPerPage(8);
      } else if (windowWidth < 1600) {
        setItemsPerPage(10);
      } else {
        setItemsPerPage(13);
      }
    };
    handleResize();

    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the resize event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // items per page depends on windows height ***********************************

  const [itemsPerPage, setItemsPerPage] = useState(12);

  const value = getRole();

  const token = getCookie();

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShow = (id) => {
    setShow(true);
    setID(id);
  };

  const handleClose1 = () => {
    setShow1(false);
    setCategory({ id: 0, name: "" });
  };

  const fetchCategories = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/category/get`
      );

      setCategories(data?.data?.data);
    } catch (err) {}
  };

  const handleDelete = async (id) => {
    try {
      const data = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/category/delete/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setShow(false);
      fetchCategories();
    } catch (error) {
      if (error?.response?.status === 500) {
        setPopupMessage(`Server error: ${error?.response?.data?.message}`);
        setLoading(true);
        setStatus(false);
      } else {
        setPopupMessage("An error occurred while making the request");
        setLoading(true);
        setStatus(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!category?.name) {
        setPopupMessage("Please enter a Name.");
        setLoading(true);
        setStatus(false);
      } else {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/category${dynamic}`,
            {
              name: category?.name.trim(),
            },
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );

          setShow1(false);

          setPopupMessage("Form submitted successfully!");
          setLoading(true);
          setStatus(true);

          setCategory({ id: 0, name: "" });

          fetchCategories();
        } catch (error) {
          if (error?.response?.status === 405) {
            setPopupMessage(`Category with the same name already exists`);
            setLoading(true);
            setStatus(false);
          } else if (error?.response?.status === 500) {
            setPopupMessage(`Server error: ${error?.response?.data?.message}`);
            setLoading(true);
            setStatus(false);
          } else {
            setPopupMessage("An error occurred while making the request");
            setLoading(true);
            setStatus(false);
          }
        }
      }
    } catch (err) {}
  };

  const handleName = (values) => {
    if (values.length > 50) return;
    setCategory({ ...category, name: values });
  };

  const productsExist = categories && categories.length > 0;

  const allProductsArray = productsExist ? categories : [];

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = allProductsArray.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div>
      <div className="">
        <div className="container-1600">
          <div className="left-right-bar">
            <Sidebar />

            <section
              className="admin-side-side equal-height


 table-boxes"
            >
              <div className="admin-catg">
                <h1
                  className="mean-admin-title"
                  style={{ color: "var(--Slate)", fontWeight: "600" }}
                >
                  Categories
                </h1>

                <div className="showTable mt-2">
                  <ul>
                    <li>
                      {/* <Link to="">All ({categories?.length})</Link> */}
                    </li>
                  </ul>
                  <div className="pagin text-end  d-flex gap-3">
                    <Link
                      to=""
                      className="login-btn"
                      onClick={() => {
                        setDynamic("/add"), setShow1(true);
                      }}
                    >
                      Add
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tabsignupreq">
                <div className="practionar-request customer pract-request">
                  <div className="latest-order-box-parent">
                    <div className="fi-order-box">
                      <ul className="order-list">
                        <li>
                          <a>S. No</a>
                        </li>
                        <li>
                          <a>Category</a>
                        </li>
                        <li>
                          <a>Date Modified</a>
                        </li>
                        <li>
                          <a>Action</a>
                        </li>
                      </ul>
                    </div>

                    {currentProducts?.map((category, index) => (
                      <div className="sec-order-box">
                        <ul className="sec-order-list">
                          <li>
                            <span>
                              {index + 1 + (currentPage - 1) * itemsPerPage}
                            </span>
                          </li>
                          <li>
                            <a>{category?.name}</a>
                          </li>
                          <li className="set-color-icn">
                            <a>{formatDate(category?.updatedAt)}</a>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                setDynamic(`/edit/${category?.id}`);
                                setShow1(true);
                                setCategory({
                                  ...category,
                                  name: category?.name,
                                });
                              }}
                            >
                              <i
                                className="fa-solid fa-pencil"
                                style={{
                                  marginRight: 20,
                                  color: "var(--Slate)",
                                }}
                              />{" "}
                            </button>{" "}
                            {value?.role == "1" || value?.role == "4" ? (
                              <button
                                onClick={() => {
                                  handleShow(category?.id);
                                }}
                              >
                                {" "}
                                <i
                                  className="fa-solid fa-trash"
                                  style={{ color: "var(--Slate)" }}
                                />
                              </button>
                            ) : (
                              <></>
                            )}
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                  <Pagination
                    pageCount={Math.ceil(
                      allProductsArray.length / itemsPerPage
                    )}
                    currentPage={currentPage}
                    handlePageChange={paginate}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Modal
        className="logoutmodal add-marker-modal"
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Body>
          <form onSubmit={handleSubmit} className="productadminform">
            <i
              className="fa-solid fa-xmark close-mod"
              onClick={handleClose1}
            ></i>
            <div className="form-group">
              <label>Category Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Category Name"
                name=""
                id=""
                value={category?.name}
                onChange={(e) => handleName(e.target.value)}
              />
            </div>
            <div className="biomarkerbtn">
              <button type="submit">
                {dynamic === "/add" ? "Add" : "Update"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {show && (
        <Deletepopup
          handleShow={setShow}
          text={"Category"}
          id={id}
          handleDelete={handleDelete}
        />
      )}

      {loading && (
        <ErrorPopup
          text={popupMessage}
          setText={setPopupMessage}
          status={status}
          handleShow={setLoading}
        />
      )}
    </div>
  );
}

export default Categories;
