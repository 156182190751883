// created at 03-01-2024
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "CURRENT_USER",
  initialState: {
    currentUser: false,
    customerPercentages: {},
    hasSubscription: false,
    subscription: null,
    userLoader: true,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      state.userLoader = false;
    },
    setSubscriptionsDetails: (state, action) => {
      state.subscription = action.payload;
      state.hasSubscription = action.payload?.status === 'active';
    },
    clearSubscriptionsDetails: (state, action) => {
      state.subscription = null;
      state.hasSubscription = false;
    },
    customerPercentagesAction: (state, action) => {
      state.customerPercentages = action.payload;
    },
    customerProfileUpdated: (state, action) => {
      state.profileUpdated = !state.profileUpdated;
    },
  },
});

export const {
  setCurrentUser,
  customerPercentagesAction,
  customerProfileUpdated,
  setSubscriptionsDetails,
  clearSubscriptionsDetails,
} = userSlice.actions;
export default userSlice.reducer;
