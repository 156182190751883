import React, { useState } from "react";

const Loader = () => {
  const [loader] = useState(true);
  const [zIndex] = useState(false);

  // Use CSS to add a fade-out effect
  const loaderStyles = {
    opacity: loader ? 1 : 0,
    transition: "opacity 0.8s ease-in-out",
    zIndex: 9999,
  };
  return (
    <div
      className={`loader vh-100 w-100 d-flex align-items-center justify-content-center position-fixed top-0 z-20 theme-body-bg particles-loader ${zIndex ? "-z-index-1" : ""
        }`}
      style={loaderStyles}
    >
      <img className="spinGolden" src="/static/media/bannerhomeleftimg.png" style={{ maxWidth: '7%' }} />
    </div>
  );
};

export default Loader;
