import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizHeart() {
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    let newName = "Heart" + name;

    navigate("/RecomendedTest", {
      state: { testName: newName },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Heart</h1>
        <div className="">
          <p className=" ">
            <p>
              Your heart is your body's pump and if it’s not functioning well it
              can affect nearly all aspects of your life. Have you got any
              concerns or symptoms?
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="htCheck"
              value="htCheck"
              onChange={() => goToRecommendation("CVD")}
            />
            <label className="btn btn-outline-primary" htmlFor="htCheck">
              Check risk of CVD
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="htRacing"
              value="htRacing"
              onChange={() => goToRecommendation("Palpitations")}
            />
            <label className="btn btn-outline-primary" htmlFor="htRacing">
              Racing Heart Palpitations
            </label>
          </div>
          {/* NEW CATEGORY */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="gen"
              id="gen"
              value="gen"
              onChange={() => goToRecommendation("General")}
            />
            <label className="btn btn-outline-primary" htmlFor="gen">
              General Cardiovascular Checks
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizHeart;
