import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizDiet({ data, setData, updateData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Lifestyle</h1>

        <div className="">
          <p className=" ">
            <p>
              Sharing information about your diet is crucial to assess health
              risks provide tailored advice and determine necessary
              interventions for personalised care. Do you adhere to any of the
              following diets?
            </p>
          </p>
        </div>
        <label>
          <h2 style={{ color: "var(--Slate", fontWeight: "500" }}>Diet</h2>
        </label>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Vegetarian"
              value="Vegetarian"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Hair_Loss_Check",
                  ],
                  1
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Vegetarian">
              Vegetarian
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Meat"
              value="Meat"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Hair_Loss_Check",
                  ],
                  0
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Meat">
              Meat
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Pescatarian"
              value="Pescatarian"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Hair_Loss_Check",
                  ],
                  0
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Pescatarian">
              Pescatarian
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Vegan"
              value="Vegan"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Hair_Loss_Check",
                  ],
                  1
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Vegan">
              Vegan
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Med"
              value="Med"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Hair_Loss_Check",
                  ],
                  0
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Med">
              Meditarian
            </label>
          </div>
          {/* New Category */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Med"
              value="Med"
              onClick={() =>
                updateData(
                  [
                    "Iron_Check",
                    "Zinc_Check",
                    "B_Vitamins_Check",
                    "Hair_Loss_Check",
                  ],
                  0
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Med">
              None of the Above
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizDiet;
