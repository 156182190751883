import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizFemaleHormones({ data, setData }) {
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    let newName = data?.gender + "Hormones" + name;

    navigate("/RecomendedTest", {
      state: { testName: newName },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Female Hormones</h1>
        <div className="">
          <p className=" ">
            <p>
              Hormone imbalances can lead to a lot of symptoms. Were there any
              specific symptoms that you were concerned about?
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="MHLibido"
              value="MHLibido"
              onChange={() => goToRecommendation("LowLibido")}
            />
            <label className="btn btn-outline-primary" htmlFor="MHLibido">
              Low Libido
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="MHLowEnergy"
              value="MHLowEnergy"
              onChange={() => goToRecommendation("LowEnergy")}
            />
            <label className="btn btn-outline-primary" htmlFor="MHLowEnergy">
              Low Energy
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhDysfunction"
              value="mhDysfunction"
              onChange={() => goToRecommendation("HairLoss")}
            />

            <label className="btn btn-outline-primary" htmlFor="mhDysfunction">
              Experiencing Hair Loss
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhTestlevels"
              value="mhTestlevels"
              onChange={() => goToRecommendation("Sleeping")}
            />
            <label className="btn btn-outline-primary" htmlFor="mhTestlevels">
              Trouble Sleeping
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhHairLoss"
              value="mhHairLoss"
              onChange={() => goToRecommendation("Mood")}
            />
            <label className="btn btn-outline-primary" htmlFor="mhHairLoss">
              Hot flush/Moodswings/Night Sweats
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhSleepingTrouble"
              value="mhSleepingTrouble"
              onChange={() => goToRecommendation("Periods")}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="mhSleepingTrouble"
            >
              Irregular Periods
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhHighlyStressed"
              value="mhHighlyStressed"
              onChange={() => goToRecommendation("Acne")}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="mhHighlyStressed"
            >
              Acne Oily Skin Body Hair
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhComprehensiveHormone"
              value="mhComprehensiveHormone"
              onChange={() => goToRecommendation("Weight")}
            />
            <label
              className="btn btn-outline-primary text-truncate cust-text-truncate"
              htmlFor="mhComprehensiveHormone"
            >
              Weight Gain Low Mood Sensitivity to Cold
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhNoneofabove"
              value="mhNoneofabove"
              onChange={() => goToRecommendation("Stressed")}
            />
            <label className="btn btn-outline-primary" htmlFor="mhNoneofabove">
              Highly Stressed
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="mhNoneofabove"
              value="mhNoneofabove"
              onChange={() => goToRecommendation("General")}
            />
            <label className="btn btn-outline-primary" htmlFor="mhNoneofabove">
              General Hormone Check
            </label>
          </div>
        </div>

      </div>
    </div>
  );
}

export default QuizFemaleHormones;
